import request from '@/utils/requestV2'
const qs = require('qs')

// 全部产品
export function getAllProduct (data) {
  return request({
    url: '/ooh-product/v1/product/all',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取产品下的单品剩余库存集合
export function stockQuantitys (data) {
  return request({
    url: '/ooh-product/v1/product/stockquantitys',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取单品剩余库存
export function getStock (data) {
  return request({
    url: '/ooh-product/v1/product/stockquantity',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取策略站台
export function getStationIdsByCondition (data) {
  return request({
    url: '/ooh-product/v1/condition/getstationidsbycondition',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取动态生成方案
export function getDynamicPlan (data) {
  return request({
    url: '/ooh-product/v1/product/getdynamicplan',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取动态生成方案,预算倒推
export function getPriceDynamicPlan (data) {
  return request({
    url: '/ooh-product/v1/product/getpricedynamicplan',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取按比例采购的折扣值
export function getDiscount (data) {
  return request({
    url: '/ooh-product/v1/product/getdiscount',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取sku的占比规则
export function getSkuRatio (data) {
  return request({
    url: '/ooh-product/v1/product/getskuratio',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 根据类目Id获取产品
export function getByCategory (data) {
  return request({
    url: '/ooh-product/v1/product/getbycategory',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 获取公司下的产品集合
export function getbycompanyasset (data) {
  return request({
    url: '/ooh-product/v1/product/getbycompanyasset',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 获取租户下的产品集合
export function getByPublisher (data) {
  return request({
    url: '/ooh-product/v1/product/getByPublisher',
    method: 'POST',
    data: qs.stringify(data)
  })
}
